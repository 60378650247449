import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer
} from 'recharts';
import { TooltipProps } from 'recharts';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

interface DataPoint {
  date: string;
  pressure: number;
  filtered: number | null;
  trend: number | null;
}

interface TrendLine {
  slope: number;
  intercept: number;
}

interface Targets {
  notice: number;
  change: number;
}

interface ChartData {
  dates: string[];
  pressures: number[];
  filtered_dates: string[];
  filtered_pressures: number[];
  trend_line: TrendLine;
  targets: Targets;
  centroids: number[];
}

interface PressureChartProps {
  jsonData: ChartData;
}

const PressureChart: React.FC<PressureChartProps> = ({ jsonData }) => {
  if (!jsonData) return null;

  const chartData: DataPoint[] = jsonData.dates.map((date, i) => {
    const filteredIndex = jsonData.filtered_dates.indexOf(date);
    const daysSinceStart = filteredIndex >= 0 ? filteredIndex : i;

    return {
      date,
      pressure: jsonData.pressures[i],
      filtered: filteredIndex >= 0 ? jsonData.filtered_pressures[filteredIndex] : null,
      trend: daysSinceStart >= 0 ?
        jsonData.trend_line.slope * daysSinceStart + jsonData.trend_line.intercept :
        null
    };
  });

  const formatTooltip = (value: ValueType, name: string) => {
    if (value === null || value === undefined) return ['-', name];
    const val = typeof value === 'number' ? value.toFixed(2) : value;
    const label = name;
    return [`${val} Pa`, label];
  };

  return (
    <div className="w-full mt-4">
      <div className="flex items-start gap-4">
        <div className="flex-1 h-[400px]">
          <ResponsiveContainer width="100%" height={500} className="mb-10 pb-20">
            <LineChart data={chartData} margin={{ top: 20, right: 50, left: 20, bottom: 30 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tick={{ fontSize: 12 }}
                angle={-45}
                textAnchor="end"
              />
              <YAxis
                yAxisId="left"
                domain={['auto', 'auto']}
                label={{ value: 'Pressure (Pa)', angle: -90, position: 'insideLeft' }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                domain={['auto', 'auto']}
                label={{ value: 'Pressure (inH₂O)', angle: 90, position: 'insideRight' }}
                tickFormatter={(val: number) => (val * 0.004014).toFixed(2)}
              />
              <Tooltip formatter={formatTooltip} />
              <Legend verticalAlign="bottom" height={16} wrapperStyle={{ bottom: -50 }} />
              <ReferenceLine
                y={jsonData.targets.notice}
                stroke="orange"
                strokeDasharray="3 3"
                label={{ value: 'Notice Target', position: 'right' }}
                yAxisId="left"
              />
              <ReferenceLine
                y={jsonData.targets.change}
                stroke="red"
                strokeDasharray="3 3"
                label={{ value: 'Change Target', position: 'right' }}
                yAxisId="left"
              />
              <Line
                type="monotone"
                dataKey="pressure"
                stroke="#999"
                dot={{ r: 2 }}
                name="All measurements"
                yAxisId="left"
                strokeWidth={1}
              />
              <Line
                type="monotone"
                dataKey="filtered"
                stroke="#2563eb"
                dot={{ r: 2 }}
                name="Measurements Used"
                yAxisId="left"
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="trend"
                stroke="#dc2626"
                dot={false}
                strokeDasharray="5 5"
                name="Trend"
                yAxisId="left"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <br /><br /><br />
    </div >
  );
};

export default PressureChart;
