import React, { useState, useEffect } from 'react';
import config from '../utils/config'
import { useAuth } from '../context/AuthContext';
import PressureAnalysisDisplay from '../components/PressureAnalysisDisplay';
import axiosInstance from '../utils/axiosInstance';

// Types

interface LogEntry {
  timestamp: string;
  level: string;
  message: string;
}

interface AnalysisResults {
  centroids: number[];
  change_date: string;
  chart_files: {
    html: string;
    json: string;
  };
  chart_data: {
    dates: string[];
    pressures: number[];
    filtered_dates: string[];
    filtered_pressures: number[];
    trend_line: {
      slope: number;
      intercept: number;
    };
    targets: {
      notice: number;
      change: number;
    };
    centroids: number[];
  };
  current_pressure: number;
  days_to_change: number;
  days_to_notice: number;
  notice_date: string;
  slope: number;
  activity_rating: number;
  alert_message: string;
  total_runtime: number;
  total_average_daily_runtime: number;
  logs: LogEntry[];
  status?: string;
}

interface Device {
  device_id: number;
  name: string;
  created_date: string;
  calibration_offset: number;
  centroids: null | any;
}

interface AnalysisRequest {
  use_cached: boolean;
  only_cached: boolean;
  device_name: string;
  agg: 'max' | 'avg';
  generate_charts: boolean;
  account_id: number;
  start_date: string;
  end_date: string;
}

interface OldestReadingResponse {
  date: string;
}

const yesterday = () => {
  const yesterday = new Date(new Date().setHours(0, 0, 0, 0));
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toLocaleDateString('en-CA'); // YYYY-MM-DD format
}

// Get the oldest reading date for a device from API
const getOldestPressureReading = async (device_name: string): Promise<any> => {
  // const response = await fetch(`${config.analyzeApiUrl}/analyze/oldest_reading?device_name=${device_name}`);
  // if (!response.ok) {
  //   throw new Error(`HTTP error! status: ${response.status}`);
  // }
  const response = await axiosInstance.get<OldestReadingResponse>(`${config.analyzeApiUrl}/analyze/oldest_reading?device_name=${device_name}`);
  // Get the oldest date from the response in the 'date' field
  // const data = await response.json();
  const data = response.data;
  const date_obj = new Date(data.date);
  const date = date_obj.toLocaleDateString('en-CA'); // YYYY-MM-DD format
  return date;
};

const AnalyzePage = () => {
  const [useCached, setUseCached] = useState(true);
  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [aggregationType, setAggregationType] = useState<'max' | 'avg'>('max');
  const [generateCharts, setGenerateCharts] = useState(true);
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(yesterday);
  const [oldestDate, setOldestDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [analysisResults, setAnalysisResults] = useState<AnalysisResults | null>(null);

  const { account_id } = useAuth();

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axiosInstance.get<Device[]>(`${config.devicesApiUrl}/devices`);
        const sortedDevices = [...response.data].sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
        );
        setDevices(sortedDevices);
      } catch (error) {
        console.error('Error fetching devices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);
    setAnalysisResults(null);

    const analysisData: AnalysisRequest = {
      use_cached: useCached,
      only_cached: false,
      device_name: selectedDevice,
      agg: aggregationType,
      generate_charts: generateCharts,
      account_id: account_id,
      start_date: startDate,
      end_date: endDate,
    };

    const pollInterval = 2000;
    const maxAttempts = 6; // 1 minute
    let attempts = 0;

    try {
      while (attempts < maxAttempts) {

        const response = await axiosInstance.post<AnalysisResults>(`${config.analyzeApiUrl}/analyze/filter_change`, analysisData);

        if (!response) {
          throw new Error('Analysis failed');
        }
        const data = response.data;

        // If we got the final results, break out of the polling loop
        if (data.status !== 'working') {
          setAnalysisResults(data);
          setSuccess(true);
          break;
        }
        // Next iteration will use cached results from the API
        analysisData.only_cached = true;
        // Still working, increment attempts and wait before next poll
        attempts++;
        if (attempts >= maxAttempts) {
          throw new Error('Analysis timed out after 2 minutes');
        }

        await new Promise(resolve => setTimeout(resolve, pollInterval));
      }
    } catch (err) {
      setError('Analysis failed. Please try again.');
      console.error('Error during analysis:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full w-full">
      <div className="max-w-2xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold mb-6">Analyze Filter Change</h2>

          <form onSubmit={handleSubmit} className="space-y-6">

            <div className="flex items-center justify-between">
              <label className="text-sm font-medium">Returned Cached Results</label>
              <input
                type="checkbox"
                checked={useCached}
                onChange={(e) => setUseCached(e.target.checked)}
                className="h-4 w-4"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Device</label>
              <select
                value={selectedDevice}
                onChange={async (e) => {
                  const device_name = e.target.value;
                  setSelectedDevice(device_name);
                  setStartDate(''); // Clear date while loading

                  if (device_name) {
                    try {
                      const oldest_date = await getOldestPressureReading(device_name);
                      setOldestDate(oldest_date);
                      setStartDate(oldest_date);
                    } catch (error) {
                      console.error('Error fetching oldest date:', error);
                      setError('Failed to fetch oldest reading date');
                    }
                  }
                }}
                className="w-full p-2 border rounded-md"
              >
                <option value="">Select a device</option>
                {devices.map((device) => (
                  <option key={device.device_id} value={device.name}>
                    {device.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Daily Reading Aggregation</label>
              <select
                value={aggregationType}
                onChange={(e) => setAggregationType(e.target.value as 'max' | 'avg')}
                className="w-full p-2 border rounded-md"
              >
                <option value="max">Max</option>
                <option value="avg">Avg</option>
              </select>
            </div>

            <div className="flex items-center justify-between">
              <label className="text-sm font-medium">Generate Charts</label>
              <input
                type="checkbox"
                checked={generateCharts}
                onChange={(e) => setGenerateCharts(e.target.checked)}
                className="h-4 w-4"
              />
            </div>
            <div className="flex items-center justify-between">
              <label htmlFor="startDate" className="text-sm font-medium">Start Date</label>
              <input
                id="startDate"
                name="startDate"
                type="date"
                className="p-2 border rounded-md"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                disabled={!selectedDevice}
                min={oldestDate}
                max={yesterday()}
              />
            </div>
            <div className="flex items-center justify-between">
              <label htmlFor="endDate" className="text-sm font-medium">End Date</label>
              <input
                id="endDate"
                name="endDate"
                type="date"
                className="p-2 border rounded-md"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                disabled={!selectedDevice}
                min={oldestDate}
                max={yesterday()}
              />
            </div>

            {error && (
              <div className="bg-red-50 text-red-500 p-3 rounded-md">
                {error}
              </div>
            )}

            {success && (
              <div className="bg-green-50 text-green-500 p-3 rounded-md">
                Analysis completed successfully!
              </div>
            )}

            <button
              type="submit"
              disabled={!selectedDevice || loading}
              className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              {loading ? 'Analyzing...' : 'Start Analysis'}
            </button>
          </form>
        </div>
      </div>
      {analysisResults && <PressureAnalysisDisplay data={analysisResults} />}
    </div>
  );
};

export default AnalyzePage;