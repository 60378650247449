import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import config from '../utils/config';
import { useAuth } from '../context/AuthContext';
import { FaTrash } from 'react-icons/fa';

// Types
interface Device {
  device_id: number;
  name: string;
}

interface Note {
  id: number;
  device_id: number;
  account_id: number;
  note_text: string;
  created_at: string;
}

const DeviceNotes: React.FC = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<number | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNoteText, setNewNoteText] = useState('');
  const [loading, setLoading] = useState(false);
  const { account_id } = useAuth();

  // Fetch devices
  useEffect(() => {
    const fetchDevices = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get<Device[]>(`${config.devicesApiUrl}/devices`);
        const sortedDevices = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setDevices(sortedDevices);
      } catch (error) {
        console.error('Error fetching devices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, []);

  // Fetch notes for selected device
  useEffect(() => {
    if (selectedDeviceId !== null) {
      const fetchNotes = async () => {
        try {
          setLoading(true);
          const response = await axiosInstance.get<Note[]>(`${config.notesApiUrl}/notes/device/${selectedDeviceId}`);
          setNotes(response.data);
        } catch (error) {
          console.error('Error fetching notes:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchNotes();
    } else {
      setNotes([]);
    }
  }, [selectedDeviceId]);

  // Handle note submission
  const handleAddNote = async () => {
    if (newNoteText.trim() === '' || selectedDeviceId === null) return;

    const noteData = {
      device_id: selectedDeviceId,
      account_id: account_id,
      note_text: newNoteText.trim(),
    };

    try {
      setLoading(true);
      const response = await axiosInstance.post<Note>(`${config.notesApiUrl}/notes`, noteData);
      setNotes([...notes, response.data]);
      setNewNoteText('');
    } catch (error) {
      console.error('Error adding note:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle note deletion
  const handleDeleteNote = async (noteId: number) => {
    try {
      setLoading(true);
      await axiosInstance.delete(`${config.notesApiUrl}/notes/${noteId}`);
      setNotes(notes.filter(note => note.id !== noteId));
    } catch (error) {
      console.error('Error deleting note:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Device Notes</h1>

      {/* Device Selection */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Select Device</label>
        <select
          value={selectedDeviceId ?? ''}
          onChange={(e) => setSelectedDeviceId(e.target.value ? parseInt(e.target.value) : null)}
          className="w-full p-2 border rounded-md"
        >
          <option value="">-- Select a device --</option>
          {devices.map((device) => (
            <option key={device.device_id} value={device.device_id}>
              {device.name}
            </option>
          ))}
        </select>
      </div>

      {/* Add Note */}
      {selectedDeviceId && (
        <div className="mb-6">
          <label className="block text-sm font-medium mb-2">Add Note</label>
          <textarea
            value={newNoteText}
            onChange={(e) => setNewNoteText(e.target.value)}
            className="w-full p-2 border rounded-md mb-2"
            rows={4}
            placeholder="Write your note here..."
          />
          <button
            onClick={handleAddNote}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            disabled={loading || newNoteText.trim() === ''}
          >
            Add Note
          </button>
        </div>
      )}

      {/* Notes List */}
      {selectedDeviceId && notes.length > 0 && (
        <div>
          <h2 className="text-xl font-semibold mb-4">Notes for Device</h2>
          <ul className="space-y-4">
            {notes.map((note) => (
              <li key={note.id} className="border p-4 rounded-md flex justify-between items-start">
                <div>
                  <p className="text-sm text-gray-600 mb-1">{new Date(note.created_at).toLocaleString()}</p>
                  <p className="whitespace-pre-wrap">{note.note_text}</p>
                </div>
                <button
                  onClick={() => handleDeleteNote(note.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <FaTrash />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedDeviceId && notes.length === 0 && !loading && (
        <p className="text-gray-500">No notes for this device.</p>
      )}

      {loading && <p className="text-gray-500">Loading...</p>}
    </div>
  );
};

export default DeviceNotes;