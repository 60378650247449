import React, { createContext, useState, useContext, useEffect, ReactNode, useCallback } from 'react';

interface AuthContextType {
  account_id: number;
  isLoggedIn: boolean;
  isAdmin: boolean;
  login: (token: string) => void;
  logout: () => void;
  getToken: () => string | null;
}

interface JWTPayload {
  accountId: number;
  isAdmin: boolean;
  iat?: number;
  exp?: number;
}

interface AuthProviderProps {
  children: ReactNode;
}

const decodeJWT = (token: string): JWTPayload | null => {
  try {
    const base64Payload = token.split('.')[1];
    const base64 = base64Payload.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
};

const isTokenExpired = (payload: JWTPayload): boolean => {
  if (!payload.exp) return false;
  // Add a 30-second buffer to handle timing differences
  return payload.exp * 1000 <= Date.now() + 30000;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [account_id, setAccountId] = useState<number>(-1);

  const checkAndUpdateToken = useCallback(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const payload = decodeJWT(token);
      if (payload && !isTokenExpired(payload)) {
        setAccountId(payload.accountId);
        setIsLoggedIn(true);
        setIsAdmin(payload.isAdmin);
      } else {
        // Token is invalid or expired
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setAccountId(-1);
        setIsAdmin(false);
      }
    }
  }, []);

  // Initial token check
  useEffect(() => {
    checkAndUpdateToken();
  }, [checkAndUpdateToken]);

  // Set up periodic token check
  useEffect(() => {
    const interval = setInterval(checkAndUpdateToken, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [checkAndUpdateToken]);

  const login = (token: string) => {
    const payload = decodeJWT(token);
    if (payload && !isTokenExpired(payload)) {
      localStorage.setItem('token', token);
      setAccountId(payload.accountId);
      setIsLoggedIn(true);
      setIsAdmin(payload.isAdmin);
    } else {
      console.error('Invalid or expired token provided');
      logout();
    }
  };

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setAccountId(-1);
    setIsAdmin(false);
  }, []);

  const getToken = useCallback((): string | null => {
    const token = localStorage.getItem('token');
    if (token) {
      const payload = decodeJWT(token);
      if (payload && !isTokenExpired(payload)) {
        return token;
      }
      // Token is expired or invalid
      logout();
    }
    return null;
  }, [logout]);

  return (
    <AuthContext.Provider value={{
      account_id,
      isLoggedIn,
      isAdmin, // Exposed to consumers
      login,
      logout,
      getToken
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};

export default AuthProvider;