import React from 'react';
import PressureChart from './PressureChart';
import LogDisplay, { LogEntry } from './LogDisplay';

interface ChartFiles {
  html: string;
  json: string;
}

interface ChartData {
  dates: string[];
  pressures: number[];
  filtered_dates: string[];
  filtered_pressures: number[];
  trend_line: {
    slope: number;
    intercept: number;
  };
  targets: {
    notice: number;
    change: number;
  };
  centroids: number[];
}

interface AnalysisResults {
  centroids: number[];
  change_date: string;
  chart_files: ChartFiles;
  chart_data: ChartData;
  current_pressure: number;
  days_to_change: number;
  days_to_notice: number;
  notice_date: string;
  slope: number;
  activity_rating: number;
  alert_message: string;
  total_runtime: number;
  total_average_daily_runtime: number;
  logs: LogEntry[];
  status?: string;
}

interface PressureAnalysisDisplayProps {
  data: AnalysisResults | null;
}

const PressureAnalysisDisplay: React.FC<PressureAnalysisDisplayProps> = ({ data }) => {
  if (!data) return null;

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="mt-2">
      <div className="bg-white rounded-lg shadow-md p-3">
        <h2 className="text-xl font-semibold mb-4">Analysis Results</h2>

        {data.alert_message && (
          <div className="mt-4 mb-6 border-2 border-red-600 rounded-lg p-4 bg-red-100">
            <p className="text-red-800 font-semibold text-lg">{data.alert_message}</p>
          </div>
        )}

        <div className="w-full overflow-x-auto">
          <table className="min-w-full">
            <tbody className="divide-y divide-gray-200">
              <tr className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm flex items-center">
                  <span className="mr-2">📊</span>
                  <span className="text-gray-500">Current Pressure</span>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-right font-medium">
                  {data.current_pressure.toFixed(2)} Pa
                </td>
              </tr>
              <tr className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm flex items-center">
                  <span className="mr-2">📈</span>
                  <span className="text-gray-500">Daily Change</span>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-right font-medium">
                  {data.slope.toFixed(3)} Pa/day
                </td>
              </tr>
              <tr className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm flex items-center">
                  <span className="mr-2">R</span>
                  <span className="text-gray-500">Total Runtime Minutes</span>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-right font-medium">
                  {data.total_runtime.toFixed(0)} min  ({(data.total_runtime / 60).toFixed(2)} hrs)
                </td>
              </tr>
              <tr className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm flex items-center">
                  <span className="mr-2">M</span>
                  <span className="text-gray-500">Average Daily Runtime Minutes</span>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-right font-medium">
                  {data.total_average_daily_runtime.toFixed(0)} min/day ({(data.total_average_daily_runtime / 60).toFixed(2)} hrs/day)
                </td>
              </tr>
              <tr className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm flex items-center">
                  <span className="mr-2">⚠️</span>
                  <span className="text-gray-500">Notice Date</span>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-right font-medium">
                  {formatDate(data.notice_date)} ({data.days_to_notice}d)
                </td>
              </tr>
              <tr className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm flex items-center">
                  <span className="mr-2">🔄</span>
                  <span className="text-gray-500">Change Date</span>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-right font-medium">
                  {formatDate(data.change_date)} ({data.days_to_change}d)
                </td>
              </tr>
              <tr className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm flex items-center">
                  <span className="mr-2">🌀</span>
                  <span className="text-gray-500">Centroids</span>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-right font-medium">
                  {data.centroids.map((value) => value.toFixed(2)).join(', ')}
                </td>
              </tr>
              <tr className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm flex items-center">
                  <span className="mr-2">💪</span>
                  <span className="text-gray-500">Activity Rating</span>
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-right font-medium">
                  {(data.activity_rating * 100).toFixed(1)}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>{data.chart_data && <PressureChart jsonData={data.chart_data} />}</div>

        {/* Log Display */}
        {data.logs && data.logs.length > 0 && <LogDisplay logs={data.logs} />}

        {/* Links to Charts */}
        {data.chart_files && (
          <div className="mt-6 space-y-2">
            <p className="text-sm font-medium text-gray-500">Generated Files:</p>
            <div className="space-y-2">
              <a
                href={data.chart_files.html}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-blue-600 hover:text-blue-800 text-sm"
              >
                View Interactive Chart
              </a>
              <br />
              <a
                href={data.chart_files.json}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-blue-600 hover:text-blue-800 text-sm"
              >
                Download JSON Data
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PressureAnalysisDisplay;