// src/utils/config.ts

// Environment variables
const DEV_ANALYZE_API_URL = process.env.REACT_APP_ANALYZE_API_URL;
const DEV_ACCOUNTS_API_URL = process.env.REACT_APP_ACCOUNTS_API_URL;
const DEV_DEVICES_API_URL = process.env.REACT_APP_DEVICES_API_URL;
const DEV_NOTES_API_URL = process.env.REACT_APP_NOTES_API_URL;

const PROD_ANALYZE_API_URL = process.env.REACT_APP_PROD_ANALYZE_API_URL;
const PROD_ACCOUNTS_API_URL = process.env.REACT_APP_PROD_ACCOUNTS_API_URL;
const PROD_DEVICES_API_URL = process.env.REACT_APP_PROD_DEVICES_API_URL;
const PROD_NOTES_API_URL = process.env.REACT_APP_PROD_NOTES_API_URL;

// Utility to determine the current environment
const isProduction = process.env.NODE_ENV === 'production';
console.log(`In production: ${isProduction}`)
// Centralized configuration
const config = {
  analyzeApiUrl: isProduction ? PROD_ANALYZE_API_URL : DEV_ANALYZE_API_URL,
  accountsApiUrl: isProduction ? PROD_ACCOUNTS_API_URL : DEV_ACCOUNTS_API_URL,
  devicesApiUrl: isProduction ? PROD_DEVICES_API_URL : DEV_DEVICES_API_URL,
  notesApiUrl: isProduction ? PROD_NOTES_API_URL : DEV_NOTES_API_URL,
};

export default config;