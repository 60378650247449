import React from 'react';
import DeviceNotes from '../components/DeviceNotes';

const Notes: React.FC = () => {
  return (
    <div>
      <div>
        <DeviceNotes />
      </div>
    </div>
  );
};

export default Notes;