import React from 'react';
import DeviceList from '../components/DeviceList/DeviceList';

const DeviceEdit: React.FC = () => {
  return (
    <div>
      <div>
        <DeviceList />
      </div>
    </div>
  );
};

export default DeviceEdit;