
import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';

export interface LogEntry {
  timestamp: string;
  level: string;
  message: string;
}

export const LogDisplay: React.FC<{ logs: LogEntry[] }> = ({ logs }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getLevelColor = (level: string) => {
    switch (level.toUpperCase()) {
      case 'ERROR':
        return 'text-red-600';
      case 'WARNING':
        return 'text-amber-600';
      case 'INFO':
        return 'text-blue-600';
      case 'DEBUG':
        return 'text-gray-600';
      default:
        return 'text-gray-800';
    }
  };

  const formatTimestamp = (timestamp: string) => {
    return new Date(timestamp).toLocaleString();
  };

  return (
    <div className="mt-6 border rounded-lg bg-gray-50">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-4 py-2 flex items-center justify-between text-left hover:bg-gray-100 rounded-lg transition-colors"
      >
        <div className="flex items-center space-x-2">
          {isExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
          <span className="font-medium">Analysis Logs</span>
          <span className="text-sm text-gray-500">({logs.length} entries)</span>
        </div>
      </button>

      {isExpanded && (
        <div className="p-4 space-y-2 max-h-96 overflow-y-auto">
          {logs.map((log, index) => (
            <div key={index} className="text-sm font-mono">
              <span className="text-gray-500">{formatTimestamp(log.timestamp)}</span>
              <span className={`ml-2 font-semibold ${getLevelColor(log.level)}`}>
                [{log.level}]
              </span>
              <span className="ml-2">{log.message}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LogDisplay;