import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/config';

interface Device {
  device_id: number;
  name: string;
  calibration_offset: number;
  x46_fanside: boolean;
  street_address: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  latitude: number;
  longitude: number;
  time_zone: string;
}

const DeviceList: React.FC = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchDevices = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get<Device[]>(`${config.devicesApiUrl}/devices`);
      const sortedDevices = [...response.data].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      );
      setDevices(sortedDevices);
    } catch (error) {
      console.error('Error fetching devices:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (device: Device) => setSelectedDevice(device);

  const handleSave = async () => {
    if (selectedDevice) {
      try {
        await axiosInstance.put(`${config.devicesApiUrl}/devices/${selectedDevice.device_id}`, selectedDevice);
        fetchDevices();
        setSelectedDevice(null);
      } catch (error) {
        console.error('Error saving device:', error);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (selectedDevice) {
      const { name, value } = e.target;
      setSelectedDevice({ ...selectedDevice, [name]: name === 'x46_fanside' ? value === 'true' : value });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetchDevices();
    };
    fetchData();
  }, []);

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Devices</h1>
      {loading && <p className="text-gray-500">Loading...</p>}
      <table className="table-auto w-full text-left border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2 border">ID</th>
            <th className="px-4 py-2 border">Name</th>
            <th className="px-4 py-2 border">Calibration Offset</th>
            <th className="px-4 py-2 border">Fanside</th>
            <th className="px-4 py-2 border">Street Address</th>
            <th className="px-4 py-2 border">City</th>
            <th className="px-4 py-2 border">State</th>
            <th className="px-4 py-2 border">Postal Code</th>
            <th className="px-4 py-2 border">Country</th>
            <th className="px-4 py-2 border">Time Zone</th>
            <th className="px-4 py-2 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device) => (
            <tr key={device.device_id} className="hover:bg-gray-100">
              <td className="px-4 py-2 border">{device.device_id}</td>
              <td className="px-4 py-2 border">{device.name}</td>
              <td className="px-4 py-2 border">{device.calibration_offset}</td>
              <td className="px-4 py-2 border">{device.x46_fanside ? 'Yes' : 'No'}</td>
              <td className="px-4 py-2 border">{device.street_address || 'N/A'}</td>
              <td className="px-4 py-2 border">{device.city || 'N/A'}</td>
              <td className="px-4 py-2 border">{device.state || 'N/A'}</td>
              <td className="px-4 py-2 border">{device.postal_code || 'N/A'}</td>
              <td className="px-4 py-2 border">{device.country || 'N/A'}</td>
              <td className="px-4 py-2 border">{device.time_zone || 'N/A'}</td>
              <td className="px-4 py-2 border">
                <button
                  onClick={() => handleEdit(device)}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedDevice && (
        <div className="bg-white p-6 rounded shadow-md w-full max-w-lg mx-auto">
          <h2 className="text-xl font-semibold mb-4">Edit Device</h2>
          {[
            { label: 'Name', name: 'name', type: 'text', disabled: true },
            { label: 'Calibration Offset', name: 'calibration_offset', type: 'number' },
            { label: 'Street Address', name: 'street_address', type: 'text' },
            { label: 'City', name: 'city', type: 'text' },
            { label: 'State', name: 'state', type: 'text' },
            { label: 'Postal Code', name: 'postal_code', type: 'text' },
            { label: 'Latitude', name: 'latitude', type: 'number' },
            { label: 'Longitude', name: 'longitude', type: 'number' },
          ].map(({ label, name, type, disabled }) => (
            <div className="mb-4" key={name}>
              <label className="block text-sm font-medium text-gray-700 mb-1">{label}:</label>
              <input
                type={type}
                name={name}
                value={(selectedDevice as any)[name]}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                disabled={disabled}
              />
            </div>
          ))}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">X46 Fanside:</label>
            <select
              name="x46_fanside"
              value={selectedDevice.x46_fanside ? 'true' : 'false'}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Time Zone:</label>
            <select
              name="time_zone"
              value={selectedDevice.time_zone}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
            >
              <option value="Eastern">Eastern</option>
              <option value="Central">Central</option>
              <option value="Mountain">Mountain</option>
              <option value="Pacific">Pacific</option>
            </select>
          </div>
          <div className="flex justify-between">
            <button
              onClick={handleSave}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
              Save
            </button>
            <button
              onClick={() => setSelectedDevice(null)}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceList;